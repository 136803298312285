import React, { Suspense } from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Authentication from '../authentication';
import LazyLoader from '../component/lazy-loader';


const SignUpPage = React.lazy(() => import('../pages/sign-up'));
const SignInPage = React.lazy(() => import('../pages/sign-in'));
const ResetPasswordPage = React.lazy(() => import('../pages/reset-password'));
const SelectUserTypePage = React.lazy(() => import('../pages/select-user-type'));
const VerifyToken = React.lazy(() => import('../pages/verify-token'));
const CareerSeekerPersonalDetailProfileProgressPage = React.lazy(() => import('../pages/career-seeker/profile-progress/personal-detail'));
const CareerSeekerWorkExperienceProfileProgressPage = React.lazy(() => import('../pages/career-seeker/profile-progress/work-experience'));
const CareerSeekerProfessionalSkillsProfileProgressPage = React.lazy(() => import('../pages/career-seeker/profile-progress/professional-skills'));
const CareerSeekerItSkillsProfileProgressPage = React.lazy(() => import('../pages/career-seeker/profile-progress/it-skills'));
const CareerSeekerPowerSkillsProfileProgressPage = React.lazy(() => import('../pages/career-seeker/profile-progress/power-skills'));
const CareerSeekerEducationProfileProgressPage = React.lazy(() => import('../pages/career-seeker/profile-progress/education'));
const CareerSeekerLanguageSpokenProfileProgressPage = React.lazy(() => import('../pages/career-seeker/profile-progress/language-spoken'));
const CareerSeekerProfileSummary = React.lazy(() => import('../pages/career-seeker/profile-progress/profile-summary'));
const CareerSeekerUserPaymentHistory = React.lazy(() => import('../pages/career-seeker/user-payment-history'));

const CareerSeekerSelectRolePage = React.lazy(() => import('../pages/career-seeker/select-role'));
const CareerSeekerRecommendedPathwayPage = React.lazy(() => import('../pages/career-seeker/recommended-pathway'));
const CareerSeekerMyPathPage = React.lazy(() => import('../pages/career-seeker/my-pathway'));
const CareerSeekerPowerSkillTrainingTaskProgressPage = React.lazy(() => import('../pages/career-seeker/power-skill-training-task-progress'));
const CareerSeekerEvaluationGradingDetailsPage = React.lazy(() => import('../pages/career-seeker/evaluation-grading-details'));
const CareerSeekerViewProfile = React.lazy(() => import('../pages/career-seeker/profile-progress/profile-summary'));
const CareerSeekerAuthenticatedProfileSummaryPage = React.lazy(() => import('../pages/career-seeker/profile-progress/authenticated-profile-summary'));

const CareerGrowerPersonalDetailProfileProgressPage = React.lazy(() => import('../pages/career-grower/profile-progress/personal-detail'));
const CareerGrowerWorkExperienceProfileProgressPage = React.lazy(() => import('../pages/career-grower/profile-progress/work-experience'));
const CareerGrowerProfessionalSkillsProfileProgressPage = React.lazy(() => import('../pages/career-grower/profile-progress/professional-skills'));
const CareerGrowerItSkillsProfileProgressPage = React.lazy(() => import('../pages/career-grower/profile-progress/it-skills'));
const CareerGrowerPowerSkillsProfileProgressPage = React.lazy(() => import('../pages/career-grower/profile-progress/power-skills'));
const CareerGrowerEducationProfileProgressPage = React.lazy(() => import('../pages/career-grower/profile-progress/education'));
const CareerGrowerLanguageSpokenProfileProgressPage = React.lazy(() => import('../pages/career-grower/profile-progress/language-spoken'));
const CareerGrowerProfileSummary = React.lazy(() => import('../pages/career-grower/profile-progress/profile-summary'));
const CareerGrowerAuthenticatedProfileSummaryPage = React.lazy(() => import('../pages/career-grower/profile-progress/authenticated-profile-summary'));
const CareerGrowerDashboard = React.lazy(() => import('../pages/career-grower/dashboard'));
const CareerGrowerEvaluationGradingDetailsPage = React.lazy(() => import('../pages/career-grower/evaluation-grading-details'));
const CareerGrowerPowerSkillTrainingTaskProgressPage = React.lazy(() => import('../pages/career-grower/power-skill-training-task-progress'));
const CareerGrowerUserPaymentHistory = React.lazy(() => import('../pages/career-grower/user-payment-history'));

const CareerGrowerSelectRolePage = React.lazy(() => import('../pages/career-grower/select-role'));
const CareerGrowerRecommendedPathwayPage = React.lazy(() => import('../pages/career-grower/recommended-pathway'));
const CareerGrowerMyPathPage = React.lazy(() => import('../pages/career-grower/my-pathway'));
const CareerGrowerViewProfile = React.lazy(() => import('../pages/career-grower/profile-progress/profile-summary'));

const EmployerPersonalDetailProfileProgressPage = React.lazy(() => import('../pages/employer/profile-progress/personal-detail'));
const EmployerProfileSummary = React.lazy(() => import('../pages/employer/profile-progress/profile-summary'));
const EmployerAuthenticatedProfileSummaryPage = React.lazy(() => import('../pages/employer/profile-progress/authenticated-profile-summary'));

const EmployerDashboard = React.lazy(() => import('../pages/employer/dashboard'));
const TrainingCoursesSelectedPathway = React.lazy(() => import('../pages/employer/training-courses-selected-pathway'));
const SelectedCourseDetail = React.lazy(() => import('../pages/employer/selected-course-detail'));
const OrgUpskillingPathwaysLevelWisePage = React.lazy(() => import('../pages/employer/org-upskilling-pathways-level-wise'));
const OrgUpskillingLevelWiseTrainingListPage = React.lazy(() => import('../pages/employer/org-upskilling-level-wise-pathway-trainings'));
const OrgUpskillingPowerSkillsParticipantsPage = React.lazy(() => import('../pages/employer/org-upskilling-power-skills-participants'));
const OrgUpskillingPowerSkillsTaskDetailsPage = React.lazy(() => import('../pages/employer/org-upskilling-power-skills-task-details'));
const PowerSkillsParticipantsPage = React.lazy(() => import('../pages/employer/power-skills-training-participants'));
const EvaluationGradingParticipantsPage = React.lazy(() => import('../pages/employer/evaluation-grading-particinpants'));
const PowerSkillsTaskDetailsPage = React.lazy(() => import('../pages/employer/power-skills-task-details'));
const EvaluationGradingDetailsPage = React.lazy(() => import('../pages/employer/evaluation-grading-details'));
const EmployerComletedBatchesEmployeesList = React.lazy(() => import('../pages/employer/completed-batches-users-list'));
const ShowEmpletedBatchedEmployeesProgress = React.lazy(() => import('../pages/employer/show-completed-batched-users-garding'));


const ParticipantsPage = React.lazy(() => import('../pages/admin/participants/participants'));
const ParticipantsPaymentHistoryPage = React.lazy(() => import('../pages/admin/participants/participants-payment-history'));
const ParticipantsProfileSummaryPage = React.lazy(() => import('../pages/admin/participants/participants-profile-summary'));
const ParticipantsEnrollmentsPage = React.lazy(() => import('../pages/admin/participants/participants-enrollments'));
const ParticipantsTaskDetailsPage = React.lazy(() => import('../pages/admin/participants/participants-task-details'));
const ParticipantsGradingDetailsPage = React.lazy(() => import('../pages/admin/participants/participants-grading-details'));

const CreatePathwayPage = React.lazy(() => import('../pages/admin/catalogues-pathway/create-pathway'));
const UpdatePathwayPage = React.lazy(() => import('../pages/admin/catalogues-pathway/create-pathway'));
const ViewPathwayPage = React.lazy(() => import('../pages/admin/catalogues-pathway/view-pathway-details'));
const SaveTrainingInPathwayPage = React.lazy(() => import('../pages/admin/catalogues-pathway/save-trainings-in-pathway'));
const PathwayDetailsPage = React.lazy(() => import('../pages/admin/catalogues-pathway/pathway-details'));
const PathwayListingPage = React.lazy(() => import('../pages/admin/catalogues-pathway/pathway-listing'));
const AllocatePathwayParticipantsPage = React.lazy(() => import('../pages/admin/catalogues-pathway/save-allocate-participants-into-pathway'));
const AllocateTrainingPage = React.lazy(() => import('../pages/admin/catalogues-pathway/allocate-training'));
const AllocateTrainingParticipantsPage = React.lazy(() => import('../pages/admin/skills-and-programs/allocate-training-participants'));

const ApprenticeshipLeadListPage = React.lazy(() => import('../pages/admin/delivery-consultants/apprenticeship-lead-list'));
const AllocateEvaluationFormPage = React.lazy(() => import('../pages/admin/delivery-consultants/allocate-evaluation-form'));
const CurrentApprenticeshipPage = React.lazy(() => import('../pages/admin/delivery-consultants/current-apprenticeship'));
const SaveTrainingInApprenticeshipLeadPage = React.lazy(() => import('../pages/admin/delivery-consultants/allocate-trainings-into-apprenticeship-lead'));
const ApprenticeshipLeadTrainingPage = React.lazy(() => import('../pages/admin/delivery-consultants/apprenticeship-lead-training'));
const ApprenticeshipLeadParticipantsList = React.lazy(() => import('../pages/admin/delivery-consultants/apprenticeship-participants-list'));
const ParticipantsGrading = React.lazy(() => import('../pages/admin/delivery-consultants/participants-grading'));

const SkillsAndProgramsPage = React.lazy(() => import('../pages/admin/skills-and-programs/skills-programs'));
const ManageBatchesPage = React.lazy(() => import('../pages/admin/skills-and-programs/manage-batches'));
const PastCompletedBatchesUsersPage = React.lazy(() => import('../pages/admin/skills-and-programs/past-completed-batches-users'));
const ManageActiveBatchUsersGrade = React.lazy(() => import('../pages/admin/skills-and-programs/manage-active-batch-users-grade'));
const ShowCompletedUsersBatchesProgress = React.lazy(() => import('../pages/admin/skills-and-programs/show-complete-user-batch-progress'));

const ItSkillsDb = React.lazy(() => import('../pages/admin/skills-db/it-skills-db'));
const PowerSkillsDb = React.lazy(() => import('../pages/admin/skills-db/power-skills-db'));
const LanguageSkillsDb = React.lazy(() => import('../pages/admin/skills-db/languages-skills-db'));

const ApprenticeshipLeadProfilePage = React.lazy(() => import('../pages/apprenticeship-lead/apprenticeship-lead-profile'));
const ApprenticeshipLeadGetProfileDetailsPage = React.lazy(() => import('../pages/apprenticeship-lead/apprenticeship-lead-profile-details'));
const ApprenticeshipLeadTrainingListPage = React.lazy(() => import('../pages/apprenticeship-lead/training-list'));
const ApprenticeshipLeadParticipantsPage = React.lazy(() => import('../pages/apprenticeship-lead/participants-list'));
const ApprenticeshipLeadParticipantsGradingPage = React.lazy(() => import('../pages/apprenticeship-lead/apprenship-participants-grading'));
const ShowCompletedUsersBatches = React.lazy(() => import('../pages/apprenticeship-lead/show-completed-batches'));
const ShowCompletedBatchesUsersList = React.lazy(() => import('../pages/apprenticeship-lead/completed-batches-users-list'));
const ShowCompletedBatchesUsersGradingDetails = React.lazy(() => import('../pages/apprenticeship-lead/completed-batches-users-grade'));


const EmployersListPage = React.lazy(() => import('../pages/admin/employer/employers-list'));
const EmployerPathwayDetailsPage = React.lazy(() => import('../pages/admin/employer/employer-pathway-details'));
const EmployerSelectedPathwayTrainingsPage = React.lazy(() => import('../pages/admin/employer/selected-pathway-trainings'));
const EnrolledEmployeesPage = React.lazy(() => import('../pages/admin/employer/enrolled-users'));
const EmployeeTrainingsTaskPage = React.lazy(() => import('../pages/admin/employer/user-trainings-task'));
const EmployeeGradingDetailsPage = React.lazy(() => import('../pages/admin/employer/user-grading-details'));
const EmployeeViewProfile = React.lazy(() => import('../pages/employee/profile-progress/profile-summary'));
const EmployeeAuthenticatedProfileSummaryPage = React.lazy(() => import('../pages/employee/profile-progress/authenticated-profile-summary'));
const ComletedBatchesEmployeesList = React.lazy(() => import('../pages/admin/employer/completed-batches-users'));
const ComletedBatchesEmployeesprogress = React.lazy(() => import('../pages/admin/employer/show-completed-users-progress'));

const EmployeeListPage = React.lazy(() => import('../pages/employee-list'));

const EvaluationForm = React.lazy(() => import('../pages/admin/evaluation-form/evaluation-form'));

const AdminDashboard = React.lazy(() => import('../pages/admin/dashBoard'));

const EmployeePersonalDetailProfileProgressPage = React.lazy(() => import('../pages/employee/profile-progress/personal-detail'));
const EmployeeWorkExperienceProfileProgressPage = React.lazy(() => import('../pages/employee/profile-progress/work-experience'));
const EmployeeProfessionalSkillsProfileProgressPage = React.lazy(() => import('../pages/employee/profile-progress/professional-skills'));
const EmployeeItSkillsProfileProgressPage = React.lazy(() => import('../pages/employee/profile-progress/it-skills'));
const EmployeePowerSkillsProfileProgressPage = React.lazy(() => import('../pages/employee/profile-progress/power-skills'));
const EmployeeEducationProfileProgressPage = React.lazy(() => import('../pages/employee/profile-progress/education'));
const EmployeeLanguageSpokenProfileProgressPage = React.lazy(() => import('../pages/employee/profile-progress/language-spoken'));
const EmployeeProfileSummary = React.lazy(() => import('../pages/employee/profile-progress/profile-summary'));
const EmployeeDashBoardPage = React.lazy(() => import('../pages/employee/dashboard'));
const EmployeePowerSkillTrainingTaskPrrogress = React.lazy(() => import('../pages/employee/power-skill-training-task-progress'));
const EmployeeEvaluationGradingDetails = React.lazy(() => import('../pages/employee/evaluation-grading-details'));

const CopyrightPolicyPage = React.lazy(() => import('../pages/footer/copyright-policy'));
const UserAgreementPage = React.lazy(() => import('../pages/footer/user-agreement'));
const PrivacyPolicyPage = React.lazy(() => import('../pages/footer/privacy-policy'));
const ContactUsPage = React.lazy(() => import('../pages/footer/contact-us'));

const PlanPaymentProcessPage = React.lazy(() => import('../pages/plan-payment-process'));
const CoursePaymentProcessPage = React.lazy(() => import('../pages/course-payment-process'));

const EmployeeViewProfilePage = React.lazy(() => import('../pages/employee/employee-profile-summary'));
const EmployeeEnrollmentsPage = React.lazy(() => import('../pages/employee/employee-enrollements'));
const EmployeeTaskDetailsPage = React.lazy(() => import('../pages/employee/employee-task-details'));
const EmployeeEvalutionGradingDetailsPage = React.lazy(() => import('../pages/employee/employee-grading-details'));

const Survey = React.lazy(()=> import('../pages/admin/survey/survey-list'));

function AppRouting() {
    return (
        <BrowserRouter>
            <Suspense fallback={<LazyLoader />}>
                <Routes>
                    <Route path="/" element={<SignInPage />} />
                    <Route path="/sign-up" element={<SignUpPage />} />
                    <Route path="/reset-password" element={<ResetPasswordPage />} />
                    <Route path="/linkedin" element={<LinkedInCallback />} />
                    <Route path="/select-user-type" element={<SelectUserTypePage userType={null} />} />

                    <Route path="/career-seeker/profile-progress/personal-detail" element={<Authentication element={<CareerSeekerPersonalDetailProfileProgressPage />} />} />
                    <Route path="/career-seeker/profile-progress/work-experience" element={<Authentication element={<CareerSeekerWorkExperienceProfileProgressPage />} />} />
                    <Route path="/career-seeker/profile-progress/professional-skills" element={<Authentication element={<CareerSeekerProfessionalSkillsProfileProgressPage />} />} />
                    <Route path="/career-seeker/profile-progress/it-skills" element={<Authentication element={<CareerSeekerItSkillsProfileProgressPage />} />} />
                    <Route path="/career-seeker/profile-progress/power-skills" element={<Authentication element={<CareerSeekerPowerSkillsProfileProgressPage />} />} />
                    <Route path="/career-seeker/profile-progress/education" element={<Authentication element={<CareerSeekerEducationProfileProgressPage />} />} />
                    <Route path="/career-seeker/profile-progress/language-spoken" element={<Authentication element={<CareerSeekerLanguageSpokenProfileProgressPage />} />} />
                    <Route path="/career-seeker/profile-summary" element={<Authentication element={<CareerSeekerProfileSummary />} />} />

                    <Route path="/career-seeker/select-role" element={<Authentication element={<CareerSeekerSelectRolePage />} />} />
                    <Route path="/career-seeker/recommended-pathway" element={<Authentication element={<CareerSeekerRecommendedPathwayPage />} />} />
                    <Route path="/career-seeker/my-pathway" element={<Authentication element={<CareerSeekerMyPathPage />} />} />
                    <Route path="/career-seeker/power-skill-training-progress" element={<Authentication element={<CareerSeekerPowerSkillTrainingTaskProgressPage />} />} />
                    <Route path="/career-seeker/evaluation-grading-details" element={<Authentication element={<CareerSeekerEvaluationGradingDetailsPage />} />} />
                    <Route path="/career-seeker/view-profile-summary" element={<Authentication element={<CareerSeekerViewProfile />} />} />
                    <Route path="/career-seeker/authenticated-profile-summary" element={<Authentication element={<CareerSeekerAuthenticatedProfileSummaryPage />} />} />
                    <Route path="/career-seeker/user-payment-history" element={<CareerSeekerUserPaymentHistory />} />

                    {/* <Route path="*" element={<Navigate replace to="/" />} /> */}

                    <Route path="/career-grower/profile-progress/personal-detail" element={<Authentication element={<CareerGrowerPersonalDetailProfileProgressPage />} />} />
                    <Route path="/career-grower/profile-progress/work-experience" element={<Authentication element={<CareerGrowerWorkExperienceProfileProgressPage />} />} />
                    <Route path="/career-grower/profile-progress/professional-skills" element={<Authentication element={<CareerGrowerProfessionalSkillsProfileProgressPage />} />} />
                    <Route path="/career-grower/profile-progress/it-skills" element={<Authentication element={<CareerGrowerItSkillsProfileProgressPage />} />} />
                    <Route path="/career-grower/profile-progress/power-skills" element={<Authentication element={<CareerGrowerPowerSkillsProfileProgressPage />} />} />
                    <Route path="/career-grower/profile-progress/education" element={<Authentication element={<CareerGrowerEducationProfileProgressPage />} />} />
                    <Route path="/career-grower/profile-progress/language-spoken" element={<Authentication element={<CareerGrowerLanguageSpokenProfileProgressPage />} />} />
                    <Route path="/career-grower/profile-summary" element={<Authentication element={<CareerGrowerProfileSummary />} />} />
                    <Route path="/career-grower/authenticated-profile-summary" element={<Authentication element={<CareerGrowerAuthenticatedProfileSummaryPage />} />} />

                    <Route path="/career-grower/dashboard" element={<Authentication element={<CareerGrowerDashboard />} />} />
                    <Route path="/career-grower/evaluation-grading-details" element={<Authentication element={<CareerGrowerEvaluationGradingDetailsPage />} />} />
                    <Route path="/career-grower/power-skill-training-progress" element={<Authentication element={<CareerGrowerPowerSkillTrainingTaskProgressPage />} />} />
                    <Route path="/career-grower/user-payment-history" element={<CareerGrowerUserPaymentHistory />} />
                    <Route path="/career-grower/select-role" element={<Authentication element={<CareerGrowerSelectRolePage />} />} />
                    <Route path="/career-grower/recommended-pathway" element={<Authentication element={<CareerGrowerRecommendedPathwayPage />} />} />
                    <Route path="/career-grower/my-pathway" element={<Authentication element={<CareerGrowerMyPathPage />} />} />
                    <Route path="/career-grower/view-profile-summary" element={<Authentication element={<CareerGrowerViewProfile />} />} />

                    <Route path="/employer/profile-progress/personal-detail" element={<Authentication element={<EmployerPersonalDetailProfileProgressPage />} />} />
                    <Route path="/employer/authenticated-profile-summary" element={<Authentication element={<EmployerAuthenticatedProfileSummaryPage />} />} />
                    <Route path="/employer/profile-summary" element={<Authentication element={<EmployerProfileSummary />} />} />
                    <Route path="/employer/dashboard" element={<Authentication element={<EmployerDashboard />} />} />
                    <Route path="/employer/training-courses-selected-pathway" element={<Authentication element={<TrainingCoursesSelectedPathway />} />} />
                    <Route path="/employer/selected-course-detail" element={<Authentication element={<SelectedCourseDetail />} />} />
                    <Route path="/employer/org-upskilling-pathways-level-wise" element={<Authentication element={<OrgUpskillingPathwaysLevelWisePage />} />} />
                    <Route path="/employer/org-upskilling-level-wise-pathway-training-list" element={<Authentication element={<OrgUpskillingLevelWiseTrainingListPage />} />} />
                    <Route path="/employer/org-upskilling-power-skills-participants" element={<Authentication element={<OrgUpskillingPowerSkillsParticipantsPage />} />} />
                    <Route path="/employer/org-upskilling-power-skills-task-details" element={<Authentication element={<OrgUpskillingPowerSkillsTaskDetailsPage />} />} />
                    <Route path="/employer/power-skills-participants" element={<Authentication element={<PowerSkillsParticipantsPage />} />} />
                    <Route path="/employer/evaluation-grading-participants" element={<Authentication element={<EvaluationGradingParticipantsPage />} />} />
                    <Route path="/employer/power-skills-task-details" element={<Authentication element={<PowerSkillsTaskDetailsPage />} />} />
                    <Route path="/employer/evaluation-grading-details" element={<Authentication element={<EvaluationGradingDetailsPage />} />} />
                    <Route path="/employer/completed-batches-users-list" element={<Authentication element={<EmployerComletedBatchesEmployeesList />} />} />
                    <Route path="/employer/completed-batches-users-progress" element={<Authentication element={<ShowEmpletedBatchedEmployeesProgress />} />} />

                    <Route path="/employee/profile-progress/personal-detail" element={<Authentication element={<EmployeePersonalDetailProfileProgressPage />} />} />
                    <Route path="/employee/profile-progress/work-experience" element={<Authentication element={<EmployeeWorkExperienceProfileProgressPage />} />} />
                    <Route path="/employee/profile-progress/professional-skills" element={<Authentication element={<EmployeeProfessionalSkillsProfileProgressPage />} />} />
                    <Route path="/employee/profile-progress/it-skills" element={<Authentication element={<EmployeeItSkillsProfileProgressPage />} />} />
                    <Route path="/employee/profile-progress/power-skills" element={<Authentication element={<EmployeePowerSkillsProfileProgressPage />} />} />
                    <Route path="/employee/profile-progress/education" element={<Authentication element={<EmployeeEducationProfileProgressPage />} />} />
                    <Route path="/employee/profile-progress/language-spoken" element={<Authentication element={<EmployeeLanguageSpokenProfileProgressPage />} />} />
                    <Route path="/employee/profile-summary" element={<Authentication element={<EmployeeProfileSummary />} />} />
                    <Route path="/employee/dashboard" element={<Authentication element={<EmployeeDashBoardPage />} />} />
                    <Route path="/employee/power-skill-training-progress" element={<Authentication element={<EmployeePowerSkillTrainingTaskPrrogress />} />} />
                    <Route path="/employee/evaluation-grading-details" element={<Authentication element={<EmployeeEvaluationGradingDetails />} />} />
                    <Route path="/employee/view-profile-summary" element={<Authentication element={<EmployeeViewProfile />} />} />
                    <Route path="/employee/authenticated-profile-summary" element={<Authentication element={<EmployeeAuthenticatedProfileSummaryPage />} />} />

                    <Route path="/admin/dashboard" element={<Authentication element={<AdminDashboard />} />} />
                    <Route path="/admin/participants" element={<Authentication element={<ParticipantsPage />} />} />
                    <Route path="/admin/participants/profile-summary/:id" element={<Authentication element={<ParticipantsProfileSummaryPage />} />} />
                    <Route path="/admin/participants/task-details" element={<Authentication element={<ParticipantsTaskDetailsPage />} />} />
                    <Route path="/admin/participants/grading-details" element={<Authentication element={<ParticipantsGradingDetailsPage />} />} />
                    <Route path="/admin/participants/enrollments" element={<Authentication element={<ParticipantsEnrollmentsPage />} />} />
                    <Route path="/admin/evaluation-form" element={<Authentication element={<EvaluationForm />} />} />
                    <Route path="/admin/participants/payment-history" element={<Authentication element={<ParticipantsPaymentHistoryPage />} />} />
                    <Route path="/admin/survey" element={<Authentication element={<Survey />} />} />

                    <Route path="/admin/catalogues-pathway/create-pathway" element={<Authentication element={<CreatePathwayPage />} />} />
                    <Route path="/admin/catalogues-pathway/update-pathway" element={<Authentication element={<UpdatePathwayPage />} />} />
                    <Route path="/admin/catalogues-pathway/view-pathway-details" element={<Authentication element={<ViewPathwayPage />} />} />
                    <Route path="/admin/catalogues-pathway/save-trainings-in-pathway" element={<Authentication element={<SaveTrainingInPathwayPage />} />} />
                    <Route path="/admin/catalogues-pathway/pathway-details" element={<Authentication element={<PathwayDetailsPage />} />} />
                    <Route path="/admin/catalogues-pathway/pathway-listing" element={<Authentication element={<PathwayListingPage />} />} />
                    <Route path="/admin/catalogues-pathway/save-allocate-pathway-participants" element={<Authentication element={<AllocatePathwayParticipantsPage />} />} />
                    <Route path="/admin/catalogues-pathway/allocate-training" element={<Authentication element={<AllocateTrainingPage />} />} />

                    <Route path="/admin/delivery-consultants/apprenticeship-lead-list" element={<Authentication element={<ApprenticeshipLeadListPage />} />} />
                    <Route path="/admin/delivery-consultants/allocate-evaluation-form" element={<Authentication element={<AllocateEvaluationFormPage />} />} />
                    <Route path="/admin/delivery-consultants/save-trainings-in-apprenticeship-lead" element={<Authentication element={<SaveTrainingInApprenticeshipLeadPage />} />} />
                    <Route path="/admin/delivery-consultants/current-apprenticeship" element={<Authentication element={<CurrentApprenticeshipPage />} />} />
                    <Route path="/admin/delivery-consultants/apprenticeship-lead-training" element={<Authentication element={<ApprenticeshipLeadTrainingPage />} />} />
                    <Route path="/admin/delivery-consultants/apprenticeship-lead-participants-list" element={<Authentication element={<ApprenticeshipLeadParticipantsList />} />} />
                    <Route path="/admin/delivery-consultants/participant-grading" element={<Authentication element={<ParticipantsGrading />} />} />

                    <Route path="/admin/skills-and-programs/skills-programs" element={<Authentication element={<SkillsAndProgramsPage />} />} />
                    <Route path="/admin/skills-db/it-skills-db" element={<Authentication element={<ItSkillsDb />} />} />
                    <Route path="/admin/skills-db/power-skills-db" element={<Authentication element={<PowerSkillsDb />} />} />
                    <Route path="/admin/skills-db/languages-skills-db" element={<Authentication element={<LanguageSkillsDb />} />} />
                    <Route path="/admin/skills-and-programs/manage-batches" element={<Authentication element={<ManageBatchesPage />} />} />
                    <Route path="/admin/skills-and-programs/show-completed-batche-user-progress" element={<Authentication element={<ShowCompletedUsersBatchesProgress />} />} />
                    <Route path="/admin/skills-and-programs/show-past-completed-batches-users-list" element={<Authentication element={<PastCompletedBatchesUsersPage />} />} />
                    <Route path="/admin/skills-and-programs/manage-active-batch-users-grade" element={<Authentication element={<ManageActiveBatchUsersGrade />} />} />
                    <Route path="/admin/skills-and-programs/allocate-training-participants" element={<Authentication element={<AllocateTrainingParticipantsPage />} />} />

                    <Route path="/apprenticeship-lead-profile" element={<Authentication element={<ApprenticeshipLeadProfilePage />} />} />
                    <Route path="/apprenticeship-lead-get-profile-details" element={<Authentication element={<ApprenticeshipLeadGetProfileDetailsPage />} />} />
                    <Route path="/apprenticeship-lead-training-list" element={<Authentication element={<ApprenticeshipLeadTrainingListPage />} />} />
                    <Route path="/apprenticeship-lead-participants-list" element={<Authentication element={<ApprenticeshipLeadParticipantsPage />} />} />
                    <Route path="/apprenticeship-lead-participant-grading" element={<Authentication element={<ApprenticeshipLeadParticipantsGradingPage />} />} />
                    <Route path="/apprenticeship-lead-show-completed-user-batches" element={<Authentication element={<ShowCompletedUsersBatches />} />} />
                    <Route path="/apprenticeship-lead-show-completed-batches-users-list" element={<Authentication element={<ShowCompletedBatchesUsersList />} />} />
                    <Route path="/apprenticeship-lead-show-completed-batches-users-grading-details" element={<Authentication element={<ShowCompletedBatchesUsersGradingDetails />} />} />

                    <Route path="/admin/employer/employers-list" element={<Authentication element={<EmployersListPage />} />} />
                    <Route path="/admin/employer/employer-pathway-details" element={<Authentication element={<EmployerPathwayDetailsPage />} />} />
                    <Route path="/admin/employer/employer-training-list" element={<Authentication element={<EmployerSelectedPathwayTrainingsPage />} />} />
                    <Route path="/admin/employer/enrolled-users" element={<Authentication element={<EnrolledEmployeesPage />} />} />
                    <Route path="/admin/employer/user-training-task" element={<Authentication element={<EmployeeTrainingsTaskPage />} />} />
                    <Route path="/admin/employer/user-grading-details" element={<Authentication element={<EmployeeGradingDetailsPage />} />} />
                    <Route path="/admin/employer/completed-batches-users-list" element={<Authentication element={<ComletedBatchesEmployeesList />} />} />
                    <Route path="/admin/employer/show-completed-users-batches-progress" element={<ComletedBatchesEmployeesprogress />} />

                    <Route path="/copyright-policy" element={<CopyrightPolicyPage />} />
                    <Route path="/user-agreement" element={<UserAgreementPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="/contact-us" element={<ContactUsPage />} />

                    <Route path="/verify-token" element={<VerifyToken />} />

                    <Route path="/plan-payment-process" element={<PlanPaymentProcessPage />} />
                    <Route path="/course-payment-process" element={<CoursePaymentProcessPage />} />

                    <Route path="/employee/view-profile/:id" element={<EmployeeViewProfilePage />} />
                    <Route path="/employee/employee-list" element={<EmployeeListPage />} />
                    <Route path="/employee/enrollments" element={<EmployeeEnrollmentsPage />} />
                    <Route path="/employee/task-details" element={<EmployeeTaskDetailsPage />} />
                    <Route path="/employee/evalution-grading-details" element={<EmployeeEvalutionGradingDetailsPage />} />

                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default AppRouting;
