import React from 'react'
import { Navigate } from 'react-router-dom';
import { getLocalStorageItem } from './services/shared-service'
import { StorageKeyEnum } from './utils/enum'

function Authentication({ element }: { element: JSX.Element }) {
    const token = getLocalStorageItem(StorageKeyEnum.AUTHTOKEN);
    if (!token) {
        return <Navigate to="/" />;
    }
    return element;
}

export default Authentication