import React from 'react';

function LazyLoader() {
    return (
        <div className="preloader">
            <div id='preloader-logo' className='d-flex align-items-center justify-content-center flex-column'>
                <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth={6} strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
                <strong className='mt-3'> Please Wait... </strong>
            </div>
        </div>
    )
}

export default LazyLoader;