declare var $: any;

export const IsNullOrUndefined = (s: any): boolean => {
    return s === null || s === undefined;
}

export const IsNullOrEmptyArray = (s: any[]): boolean => {
    return IsNullOrUndefined(s) || s.length === 0;
}

export const IsNumberNullOrUndefined = (o: number): boolean => {
    return IsNullOrUndefined(o);
}

export const IsStringNullEmptyOrUndefined = (str: string): boolean => {
    return IsNullOrUndefined(str) || str === '';
}

export const IsEmptyObject = (obj: Object): boolean => {
    return !Object.keys(obj).length;
}

export const setLocalStorageItem = (key: string, value: string): void => {
    return sessionStorage.setItem(key, value);
}

export const getLocalStorageItem = (key: string): string | null => {
    return sessionStorage.getItem(key);
}

export const validateFileExtension = (fileName: string, fileExtension: string[]): boolean => {
    const extension: string | undefined = fileName.split('.').pop();
    if (extension) {
        return fileExtension.some(ext => extension.includes(ext));
    }
    return false;
}

export const scrollTop = (): void => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

export const getBase64FromFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
}

export const getFileName = (filePath: string): string => {
    const index = filePath.lastIndexOf('/');
    return filePath.substring(index + 1);
}

export const removeSpaceOnFirstCharacter = (event: any): void => {
    if (event.target.selectionStart === 0 && event.code === "Space") {
        event.preventDefault();
    }
}

export const getPathwayCategoryNameFromEnum = (category: string): string => {
    return IsStringNullEmptyOrUndefined(category) ? '' : category.replaceAll("_", " ").toLocaleUpperCase();
}

export const threeDotsGropDownIssue = () => {
    $(document).ready(function () {
        $('.table-responsive').on('show.bs.dropdown', function () {
            $('.table-responsive').css("overflow", "inherit");
        });
        $('.table-responsive').on('hide.bs.dropdown', function () {
            $('.table-responsive').css("overflow", "auto");
        });
    });
}

export const removeMF = (role:string) => {
  return role.replace('(m/f)','')
}