import React, { useEffect } from 'react';

// react phone input
import 'react-phone-input-2/lib/style.css'

// react-toastify
import 'react-toastify/dist/ReactToastify.css';

// Prime React
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
//icons

import './App.css';

import AppRouting from './routing/app-routing';
import { scrollTop } from './services/shared-service';
import { ToastContainer } from 'react-toastify';

function App() {

  useEffect(() => {
    console.log(`Last updated on 30-06-2023-#1`);
    scrollTop();
    // $(document).ready(function () {

    // });
    // initializeApp({
    //   apiKey: process.env.FIREBASE_CONFIG_APIKEY,
    //   authDomain: process.env.FIREBASE_CONFIG_AUTHDOMAIN,
    //   projectId: process.env.FIREBASE_CONFIG_PROJECTID,
    //   storageBucket: process.env.FIREBASE_CONFIG_STORAGEBUCKET,
    //   messagingSenderId: process.env.FIREBASE_CONFIG_MESSAGINGSENDERID,
    //   appId: process.env.FIREBASE_CONFIG_APPID,
    //   measurementId: process.env.FIREBASE_CONFIG_MEASUREMENTID,
    // });
  }, []);

  return (
    <>
      <ToastContainer />
      <AppRouting />
    </>
  );
}

export default App;
