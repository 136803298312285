export enum UserTypeEnum {
    EMPLOYER = "employer",
    CAREER_SEEKER = "career_seeker",
    CAREER_GROWER = "career_grower",
    ADMIN = 'admin',
    EMPLOYEE = 'employee',
    APPRENTICESHIP_LEAD = 'apprenticeship_lead',
}

export enum FileExtensionEnum {
    DOCX = "docx",
    DOC = "doc",
    PDF = "pdf",
    PNG = "png",
    JPEG = "jpeg",
    JPG = "jpg",
    BMP = "bmp",
    GIF = "gif",
}

export enum StorageKeyEnum {
    AUTHTOKEN = 'authToken',
    USERID = 'userId',
    PROFILEID = 'profileId',
    ISPROFILESUMMARY = 'isProfileSummary',
    USERTYPE = 'userType',
    PARTICIPANTPAGESCHANGEUSERTYPE = 'participantPageChangeUserType',
    CURRENTPATHWAYOBJ = 'currentpathwayobj',
    USERINFO = 'userInfo',
    CAREER_SEEKER_SELECTED_ROLE = 'career_seeker_selected_role',
    CAREER_GROWER_SELECTED_ROLE = 'career_grower_selected_role',
    COURSEOBJ = 'courseobj',
    STRIPE_CREATE_PAYMENT_SESSION_REQ = 'stripe_create_payment_session_req',
    ISPROFILESUMMARYPAGE = 'isProfileSummaryPage',
    ROLEID = 'roleId',
    ISDASHBOARDPAGE = 'isDashboardPage',
    USERSROLE = 'users_role'
}

export enum EmployerStatusEnum {
    REGISTERED = 'registered',
    SUBMITTED = 'submitted',
    APPROVED = 'approved',
    REJECTED = 'rejected'
}

export enum SkillsDBEnum {
    IT_SKILLS = 'It Skills',
    POWER_SKILLS = 'Power Skills',
    LANGUAGE_SKILLS = 'Languages Skills',
}


export enum UserAuthProviderEnum {
    DEFALUT = 'default',
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
    LINKEDIN = 'linkedIn',
}

export enum EvaluationFormCategoryEnum {
    OVERALL_EVALUATION = "overall_evaluation",
    PROJECT_EVALUATION = "project_evaluation",
}

export enum PathwayCategoryEnum {
    ORGANIZATIONAL_UPSKILLING = 'organizational_upskilling',
    INTERNAL_CAREER_PATHING = 'internal_career_pathing',
    EXTERNAL_TALENT_SUPPLY = 'external_talent_supply',
}

export enum ProviderEnum {
    AGILIXDAWN = 'agilix_dawn',
    UDEMY = 'udemy',
}

export enum AgilixDawnTrainingsTagEnum {
    POWERSKILLDEVELOPMENT = 'power-skills-development',
    INDUSTRYSKILLS = 'industry-skills',
    INDUSTRYTOOLS = 'industry-tools',
    EXPERIENCE = 'experience',
    PROFESSIONALDEVELOPMENT = 'professional-development'
}

export enum PathwayLevelEnum {
    HIGHPOTENTIAL = 'high_potential',
    EXECUTIVE = "executive"
}

export enum AgilixDawnTrainingsStatusEnum {
    ALL = 'all',
    ENABLED = 'enabled',
    DISABLED = 'disabled',
}

export enum PlanEnum {
    FREE = 'free',
    ONE_TIME_FEE = 'one_time_fee',
}

export enum PlanPaymentFromEnum {
    PROFILESUMMARYPAGE = 'profileSummaryPage',
    SELECTROLEPAGE = 'selectRolePage',
    PURCHASEDCOURSE = 'purchasedCourse',
}
